<script lang="ts">
export default {
  layout: 'onboard',
}
</script>

<template>
  <div class="min-h-screen relative text-sm text-gray-500 flex flex-col">
    <BaseOrganismsMainNavbar :hide-menus="true" />
    <main class="pb-20 lg:pb-40">
      <slot />
    </main>
  </div>
</template>
